// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var country_detail = "Country-module--country_detail--1Phgd";
export var lists = "Country-module--lists--ubrcA";
export var grid_item = "Country-module--grid_item--1F8dt";
export var inner_content = "Country-module--inner_content--3nwka";
export var all_countries_link = "Country-module--all_countries_link--1fcdZ";
export var country_all_text = "Country-module--country_all_text--26p60";
export var image_text_1 = "Country-module--image_text_1--1nFGK";
export var more_information = "Country-module--more_information--2uLh_";
export var button = "Country-module--button--2CQyV";
export var more_heading = "Country-module--more_heading--3xTAI";
export var faq_heading = "Country-module--faq_heading--2XAZF";
export var sticky_div = "Country-module--sticky_div--1dRFu";
export var button_start = "Country-module--button_start--hXSQq";
export var main_section = "Country-module--main_section--1uype";
export var duration_section = "Country-module--duration_section--3qtaO";
export var extend_vise = "Country-module--extend_vise--3UaDw";
export var find_job = "Country-module--find_job--1jPS-";
export var types = "Country-module--types--2vuK0";
export var span = "Country-module--span--GjMcv";
export var more_setting = "Country-module--more_setting--laV-9";
export var more_information_1 = "Country-module--more_information_1--3-2iR";
export var button_content = "Country-module--button_content--1WNqW";
export var open = "Country-module--open--28A9u";
export var cta_button_mobile = "Country-module--cta_button_mobile--35GzK";
export var more_info_wrapper = "Country-module--more_info_wrapper--nhBXQ";