import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Button from "../../Button/Button";
import { useState } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import * as styles from "./Country.module.scss";
const CountryDetail = ({ post, setModal }) => {
  const [show, setShow] = useState(false);
  return (
    <div className={styles.country_detail}>
      <div className={styles.more_setting}>
        <div className={styles.more_information_1}>
          {!show && <FaAngleUp onClick={() => setShow(!show)} />}
          {show && <FaAngleDown onClick={() => setShow(!show)} />}
          {show && (
            <div className={`${styles.button_content}`}>
              <MoreInfoButton activeSlug={post?.slug} setModal={setModal} />
            </div>
          )}
          <div className={styles.more_info_wrapper}>
            <div className={styles.more_heading}>
              <span onClick={() => setShow(!show)}>More info</span>
            </div>
            <div>
              <Button
                className={styles.cta_button_mobile}
                text="Request a demo"
                size="large"
                contactButton={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.grid_item}>
        {post?.sectionOne && (
          <div className={styles.inner_content}>
            {post?.sec1Illustration !== null && (
              <div className={styles.image_text_1}>
                <GatsbyImage
                  image={post?.sec1Illustration?.gatsbyImageData}
                  alt={post?.sec1Illustration?.title}
                />
              </div>
            )}
            {renderRichText(post?.sectionOne)}
          </div>
        )}
        {post?.sec2Heading && post?.sectionTwo && (
          <div className={styles.duration_section}>
            <div className={styles.inner_content}>
              {post?.sec2Illustration !== null && (
                <div className={styles.image_text_1}>
                  <GatsbyImage
                    image={post?.sec2Illustration?.gatsbyImageData}
                    alt={post?.sec2Illustration?.title}
                  />
                </div>
              )}
              <h2>
                {post?.sec2Heading} <span>{post?.sec2Span}</span>
              </h2>
              {renderRichText(post?.sectionTwo)}
            </div>
          </div>
        )}
        {post?.sec3Heading && post?.sectionThree && (
          <div className={styles.main_section}>
            <div className={styles.inner_content}>
              {post?.richTextImage1 !== null && (
                <div className={styles.image_text_1}>
                  <GatsbyImage
                    image={post?.richTextImage1?.gatsbyImageData}
                    alt={post?.richTextImage1?.title}
                  />
                </div>
              )}
              <h2>
                {post?.sec3Heading} <span>{post?.sec3Span}</span>
              </h2>
              {renderRichText(post?.sectionThree)}
            </div>
          </div>
        )}
        {post?.sec4Heading && post?.sectionFour && (
          <div className={styles.duration_section}>
            <div className={styles.inner_content}>
              {post?.sec4Illustration !== null && (
                <div className={styles.image_text_1}>
                  <GatsbyImage
                    image={post?.sec4Illustration?.gatsbyImageData}
                    alt={post?.sec4Illustration?.title}
                  />
                </div>
              )}
              <h2>
                {post?.sec4Heading} <span>{post?.sec4Span}</span>
              </h2>
              {renderRichText(post?.sectionFour)}
            </div>
          </div>
        )}
        {post?.sec5Heading && post?.sectionFive && (
          <div className={styles.extend_vise}>
            <div className={styles.inner_content}>
              {post?.sec5Illustration !== null && (
                <div className={styles.image_text_1}>
                  <GatsbyImage
                    image={post?.sec5Illustration?.gatsbyImageData}
                    alt={post?.sec5Illustration?.title}
                  />
                </div>
              )}
              <h2>
                {post?.sec5Heading} <span>{post?.sec5Span}</span>
              </h2>
              {renderRichText(post?.sectionFive)}
            </div>
          </div>
        )}
        {post?.sec6Heading && post?.sectionSix && (
          <div className={styles.duration_section}>
            <div className={styles.inner_content}>
              {post?.sec6Illustration !== null && (
                <div className={styles.image_text_1}>
                  <GatsbyImage
                    image={post?.sec6Illustration?.gatsbyImageData}
                    alt={post?.sec6Illustration?.title}
                  />
                </div>
              )}
              <h2>
                {post?.sec6Heading} <span>{post?.sec6Span}</span>
              </h2>
              {renderRichText(post?.sectionSix)}
            </div>
          </div>
        )}
        <div className={styles.main_section}>
          <div className={styles.inner_content}>
            {post?.richTextImage2 !== null && (
              <div className={styles.image_text_1}>
                <GatsbyImage
                  image={post?.richTextImage2?.gatsbyImageData}
                  alt={post?.richTextImage2?.title}
                />
              </div>
            )}
            {post?.sec7Heading && post?.sectionSeven && (
              <>
                <h2>
                  {post?.sec7Heading} <span>{post?.sec7Span}</span>
                </h2>
                {renderRichText(post?.sectionSeven)}
              </>
            )}
            <div className={styles.all_countries_link}>
              <a href="/eor/">
                <span>
                  <StaticImage
                    src="../../../assets/icons/country-icon.png"
                    alt="Country Icon"
                    placeholder="blurred"
                    layout="fixed"
                    width={40}
                    height={40}
                  />
                </span>
                <span className={styles.country_all_text}>
                  See all countries
                </span>
                <span className={styles.country_all_text}>
                  <FaAngleDown size={24} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.sticky_div}`}>
        <div className={styles.more_information}>
          <div className={styles.more_heading}>More information for you</div>
          <MoreInfoButton activeSlug={post?.slug} setModal={setModal} />
        </div>
      </div>
    </div>
  );
};

export default CountryDetail;
