import { graphql, useStaticQuery } from "gatsby";
import Button from "../../Button/Button";
import * as styles from "./Info.module.scss";
const MoreInfoButton = ({ activeSlug, setModal }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulCountryRequirements {
          edges {
            node {
              slug
              serviceName
            }
          }
        }
      }
    `
  );

  const routes = data?.allContentfulCountryRequirements?.edges;
  if (routes)
    return (
      <div>
        <ul className={styles.more_info_links}>
          {routes &&
            routes.map((item, index: number) => {
              //We only want related links for the same country to show up here so the first part of the slug must match
              if (
                item?.node?.slug.split("/")[0].toLowerCase() ===
                activeSlug.split("/")[0].toLowerCase()
              ) {
                return (
                  <Button
                    className={
                      activeSlug === item?.node?.slug
                        ? styles.button_start
                        : styles.button
                    }
                    text={item?.node?.serviceName}
                    size="medium"
                    url={
                      activeSlug === item?.node?.slug
                        ? ""
                        : `/eor/${item?.node?.slug}`
                    }
                    key={index}
                  />
                );
              }
            })}
          <Button
            className={styles.cta_button}
            text="Request a demo"
            size="large"
            contactButton={true}
          />
        </ul>
      </div>
    );
  else return <p>No Links</p>;
};

export default MoreInfoButton;
