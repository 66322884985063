// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var quick_section = "QuickFact-module--quick_section--BioEI";
export var grid = "QuickFact-module--grid--3ygY7";
export var grid_flag = "QuickFact-module--grid_flag--2gE_9";
export var fact_grid = "QuickFact-module--fact_grid--2s4jS";
export var grid_item = "QuickFact-module--grid_item--3Fa7A";
export var fact_img = "QuickFact-module--fact_img--3d1uA";
export var canda_title = "QuickFact-module--canda_title--9jost";
export var more_setting = "QuickFact-module--more_setting--KWsL1";
export var more_information = "QuickFact-module--more_information--3OPzJ";
export var button_content = "QuickFact-module--button_content--1hWgB";
export var more_heading = "QuickFact-module--more_heading--2TPu7";
export var button = "QuickFact-module--button--iHfrz";
export var open = "QuickFact-module--open--3pMoz";
export var button_start = "QuickFact-module--button_start--3hViJ";
export var nobr = "QuickFact-module--nobr--1fZdb";