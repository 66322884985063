import { Link } from "gatsby";
import { useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../../Button/Button";
import * as styles from "./HeroSection.module.scss";
const HeroSection = ({ post, setModal }) => {
  const countryPageSlug = post?.slug?.substring(0, post?.slug?.indexOf("/"));
  const countryBreadcrum =
    countryPageSlug.charAt(0).toUpperCase() + countryPageSlug.slice(1);
  // dynamically sets the hero section's background image using a custom css variable
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (post?.heroBackgroundImage) {
        document.documentElement.style.setProperty(
          "--hero-image",
          `url("${post?.heroBackgroundImage?.url}")`
        );
      }
    }
  }, []);

  return (
    <div>
      <div className={styles.title_wrapper}>
        <h1 className={styles.title}>{post?.serviceName}</h1>
      </div>
      <div className={styles.breadcrumbs_wrapper}>
        <div className={styles.contents}>
          <Link to="/eor/">All Countries</Link> {"> "}
          {post?.breadcrumTitle ? (
            <Link to={`/eor/${countryPageSlug}/`}>{countryBreadcrum}</Link>
          ) : (
            <strong>{countryBreadcrum}</strong>
          )}
          {post?.breadcrumTitle && (
            <>
              {" >"} <strong> {post?.breadcrumTitle} </strong>
            </>
          )}
        </div>
      </div>
      <div className={styles.hero_section}>
        <div className={styles.hero_overlay}>
          <div className={styles.inner_section}>
            <div className={styles.inner_content}>
              <div className={styles.hero_grid}>
                {post?.heroBox.map((heroBoxObj: any, index: number) => {
                  let altText: string = heroBoxObj.icon.file.fileName
                    .split(".")[0]
                    .split("_")
                    .join(" ");
                  return (
                    <div
                      className={styles.grid_item}
                      id={`heroBoxIcon_${index}`}
                    >
                      <GatsbyImage
                        image={heroBoxObj.icon.gatsbyImageData}
                        alt={altText}
                        className={styles.fact_img}
                      ></GatsbyImage>
                      <h4>{heroBoxObj.title}</h4>
                      <p>{heroBoxObj.description.description}</p>
                    </div>
                  );
                })}
              </div>
              <div className={styles.line}></div>
              <div className={styles.hero_description}>
                {post?.heroDescription?.heroDescription}
              </div>
              <Button
                className={styles.button}
                text="Request a demo"
                size="large"
                contactButton={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
