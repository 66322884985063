// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var button = "Info-module--button--3tHbn";
export var button_start = "Info-module--button_start--uM2xN";
export var more_info_links = "Info-module--more_info_links--1VCNJ";
export var cta_button = "Info-module--cta_button--1zhPY";