import React, { useEffect, useState } from "react";
import TitleValuePairI from "../../../types/TitleValuePairI.type";
import { getBorderRadius } from "../../../utils/helpers";
import * as styles from "./CountryFacts.module.scss";
import CountryFactsI from "./CountryFactsI.type";

const CountryFacts = ({ countryFacts }: CountryFactsI) => {
  const { countryFacts: facts, rows } = countryFacts;
  const [columns, setColumns] = useState(countryFacts.columns);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const currentColumns = countryFacts.columns; // Get the current columns from countryFacts
      switch (true) {
        case screenWidth <= 576:
          if (currentColumns > 1) {
            setColumns(1);
          }
          break;
        case screenWidth <= 768:
          if (currentColumns > 2) {
            setColumns(2);
          }
          break;
        case screenWidth <= 992:
          if (currentColumns > 3) {
            setColumns(3);
          }
          break;
        default:
          setColumns(currentColumns);
      }
    };

    const setupResizeHandler = () => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Call handleResize on initial page load
    };

    setupResizeHandler();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Calculate the number of items in the last full row
  let emptySpotsInLastRow = columns - (facts.length % columns);
  if (emptySpotsInLastRow === columns) {
    emptySpotsInLastRow = 0;
  }
  // Calculate the remaining empty rows
  const emptyRows = rows - Math.ceil(facts.length / columns);

  return (
    <div className={styles.countryFactsWrapper}>
      <ul
        className={styles.countryFactsGrid}
        style={{
          gridTemplateColumns: `repeat(${columns}, minmax(240px, 1fr))`,
          gridTemplateRows: `repeat(${rows}, minmax(60px, 1fr))`,
        }}
      >
        {facts.map((fact: TitleValuePairI, index: number) => {
          const borderRadius = getBorderRadius(facts.length, columns, index);
          let gridColumn = "auto";
          let gridRow = "auto";
          if (emptyRows > 0 || emptySpotsInLastRow > 0) {
            if (index === facts.length - 1) {
              // Handle the last item
              if (emptySpotsInLastRow > 0) {
                // If there are columns
                // span the remaining columns
                gridColumn = `span ${emptySpotsInLastRow + 1}`;
              }
              if (emptyRows > 0) {
                // If there are empty row,
                // span the remaining rows
                gridRow = `span ${emptyRows + 1}`;
              }
            } else if (
              emptyRows > 0 &&
              Math.ceil((index + 1) / columns) >= rows - emptyRows
            ) {
              // Handle the last row before empty rows
              gridRow = `span ${emptyRows + 1}`;
            }
          }

          return (
            <li
              key={fact.title + index} // Provide a unique key prop
              style={{
                borderRadius,
                gridColumn,
                gridRow,
              }}
              className={styles.countryFeaturesGrid__item}
            >
              <p className="countryGridFeatureTitle">{fact.title}</p>
              <div className="countryGridFeatureValue">
                <p>
                  <strong>{fact.value.value}</strong>
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CountryFacts;
