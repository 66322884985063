import { graphql } from "gatsby";
import { useState, useEffect } from "react";
import { setStyle } from "../utils/helpers";
import CountryDetail from "../components/CountryPage/CountryDetail/CountryDetail";
import Faqs from "../components/CountryPage/FAQS/Faqs";
import HeroSection from "../components/CountryPage/HeroSection/HeroSection";
import QuickFact from "../components/CountryPage/QuickFacts/QuickFact";
import Layout from "../components/Layout/Layout";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import { useCookie } from "../utils/getCookie";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import CountryFacts from "../components/CountryPage/CountryFacts/CountryFacts";

const CountryPage = ({ data, pageContext }: any) => {
  const post = data?.contentfulCountryRequirements;
  const preventIndexing =
    post?.slug.toLowerCase().indexOf("dummy") >= 0 ? true : false;
  const dataSEO = {
    metaTitle: post?.metaTitle,
    metaDescription: post?.metaDescription?.metaDescription,
    keywords: post?.metaTitle,
    preventIndexing: preventIndexing,
    alternateHrefLink: true,
    rating: true,
    faqs: post.faq,
  };

  // fix adjust the position of the hubspot chat icon on mobile
  const style =
    "@media screen and (max-width: 800px) {#hubspot-messages-iframe-container {bottom: 95px !important; width:276px;height:214px }}";

  useEffect(() => {
    if (typeof window !== "undefined") {
      setStyle(style);
    }
  }, []);

  const { title, header_background, button, page_background } =
    pageContext.data;
  const {
    footer_menu,
    header_menu,
    footer,
    logo,
    cookieBarDescription,
    cookiePolicyLink,
    cookieBarTitle,
  } = pageContext.globalSettings;
  const [showCookiesConsent, setShowCookiesConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const trackCookie = useCookie("gatsby-gdpr-tracking");
    const tagCookie = useCookie("via-gdpr-google-tagmanager");
    if (!trackCookie || !tagCookie) {
      setShowCookiesConsent(true);
    }
  }, []);
  return (
    <div>
      <Layout
        title={title}
        button={button}
        footer_menu={footer_menu}
        header_menu={header_menu}
        footer={footer}
        logo={logo}
        background={header_background}
        page_background={page_background}
        dataSEO={dataSEO}
        countryPage={true}
        blog={false}
        url={pageContext.url}
        setModal={setShowModal}
      >
        {showCookiesConsent && (
          <CookiesBanner
            dismiss={() => setShowCookiesConsent(false)}
            cookieBarDescription={cookieBarDescription}
            cookiePolicyLink={cookiePolicyLink}
            cookieBarTitle={cookieBarTitle}
          />
        )}
        <HeroSection post={post} setModal={setShowModal} />
        <QuickFact post={post} />
        {post.countryFacts ? (
          <CountryFacts countryFacts={post.countryFacts} />
        ) : null}
        <CountryDetail post={post} setModal={setShowModal} />
        <Faqs faqs={post.faq} grey={true} />
        {showModal && (
          <RegisterForm modal={showModal} setModal={setShowModal} />
        )}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query serviceData($slug: String!) {
    contentfulCountryRequirements(slug: { eq: $slug }) {
      breadcrumTitle
      slug
      metaTitle
      metaDescription {
        metaDescription
      }
      heroTitle
      heroBackgroundImage {
        url
      }
      heroBox {
        icon {
          file {
            fileName
          }
          gatsbyImageData(
            placeholder: BLURRED
            layout: FIXED
            height: 90
            quality: 100
            formats: WEBP
          )
        }
        title
        description {
          description
        }
      }
      heroDescription {
        heroDescription
      }
      countryFacts {
        countryFacts {
          title
          value {
            value
          }
        }
        rows
        columns
      }
      quickHeading
      quickSpan
      quickDescription {
        quickDescription
      }
      countryFlagImage {
        title
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          height: 140
          width: 140
          quality: 100
          formats: WEBP
        )
      }
      countryMapImage {
        url
      }
      richTextImage1 {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      richTextImage2 {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      sec1Illustration {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      sec2Illustration {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      sec4Illustration {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      sec5Illustration {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      sec6Illustration {
        gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      serviceName
      sectionOne {
        raw
      }
      sec2Heading
      sec2Span
      sectionTwo {
        raw
      }
      sec3Heading
      sec3Span
      sectionThree {
        raw
      }
      sec4Heading
      sec4Span
      sectionFour {
        raw
      }
      sec5Heading
      sec5Span
      sectionFive {
        raw
      }
      sec6Heading
      sec6Span
      sectionSix {
        raw
      }
      sec7Heading
      sec7Span
      sectionSeven {
        raw
      }
      faq {
        question
        answers {
          answers
        }
      }
      promiseTitle
      promiseSpan
    }
  }
`;

export default CountryPage;
