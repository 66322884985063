// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var hero_section = "HeroSection-module--hero_section--2pHa_";
export var hero_overlay = "HeroSection-module--hero_overlay--R95x7";
export var inner_section = "HeroSection-module--inner_section--1cwxg";
export var inner_title = "HeroSection-module--inner_title--3Ia91";
export var inner_content = "HeroSection-module--inner_content--12KIe";
export var hero_grid = "HeroSection-module--hero_grid--1HzNb";
export var grid_item = "HeroSection-module--grid_item--8xjAa";
export var fact_img = "HeroSection-module--fact_img--XnbWZ";
export var line = "HeroSection-module--line--3vTrI";
export var hero_description = "HeroSection-module--hero_description--2ZU1p";
export var button = "HeroSection-module--button--2FQy8";
export var title_wrapper = "HeroSection-module--title_wrapper--2iXC1";
export var title = "HeroSection-module--title--2fNXK";
export var breadcrumbs_wrapper = "HeroSection-module--breadcrumbs_wrapper--2lWS5";
export var contents = "HeroSection-module--contents--3hSZv";