// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var countryFactsWrapper = "CountryFacts-module--countryFactsWrapper--35PWw";
export var countryFactsGrid = "CountryFacts-module--countryFactsGrid--3drdK";
export var countryFeaturesGrid__item = "CountryFacts-module--countryFeaturesGrid__item--2zKc2";