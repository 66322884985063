import { GatsbyImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import { removeLastTwoWords, keepLastTwoWords } from "../../../utils/helpers";
import * as styles from "./QuickFact.module.scss";
const QuickFact = ({ post }) => {
  // dynamically sets the country map background image using a custom css variable
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.documentElement.style.setProperty(
        "--country-map-image",
        `url("${post?.countryMapImage?.url}")`
      );
    }
  }, []);

  return (
    <div>
      <div className={styles.quick_section}>
        <div className={styles.grid}>
          <div className={styles.grid_flag}>
            <GatsbyImage
              image={post?.countryFlagImage?.gatsbyImageData}
              alt={post?.countryFlagImage?.title}
              className={styles.fact_img}
            />
          </div>
          <div className={styles.fact_grid}>
            <div className={styles.grid_item}>
              <h2>
                {post?.quickHeading} <span>{post?.quickSpan}</span>
              </h2>
              <p>
                {removeLastTwoWords(post?.quickDescription.quickDescription)}
                <span className={styles.nobr}>
                  {keepLastTwoWords(post?.quickDescription.quickDescription)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickFact;
